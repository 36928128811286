<template>
  <div>
    <div class="text-center text-h6">Welcome to #HerMercedes</div>
    <div class="text-center">Change Password</div>
    <div class="mt-6">
      <v-form>
        <v-text-field
          label="New password"
          v-model="password"
          prepend-inner-icon="mdi-lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          :type="showPassword ? 'text' : 'password'"
          class="rounded-10"
          outlined
          @keyup.enter="checkStatus"
        />
        <v-text-field
          label="Repeat password"
          v-model="rePassword"
          prepend-inner-icon="mdi-lock"
          :append-icon="showRePassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showRePassword = !showRePassword"
          :type="showRePassword ? 'text' : 'password'"
          class="rounded-10"
          outlined
          @keyup.enter="checkStatus"
        />
        <div v-if="errorMessage" class="text-left error--text pb-5 px-4">
          {{ errorMessage }}
        </div>
      </v-form>
      <v-row no-gutters>
        <v-btn
          plain
          color="primary"
          block
          class="mb-5"
          @click="skipPasswordChange"
          >Skip</v-btn
        >
        <v-btn
          class="rounded-10"
          color="primary"
          :loading="isLoading"
          :disabled="isLoginDisabled"
          @click="submit"
          block
        >
          Save
        </v-btn>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    password: "",
    showPassword: false,
    rePassword: "",
    showRePassword: false,
    errorMessage: "",
    isLoading: false
  }),
  computed: {
    isLoginDisabled() {
      if (this.password.trim()) {
        return this.password !== this.rePassword;
      }
      return true;
    }
  },
  methods: {
    ...mapActions(["skipPasswordChange", "update"]),
    checkStatus() {
      if (this.isLoginDisabled) return;
      this.submit();
    },
    submit() {
      this.errorMessage = "";
      this.isLoading = true;
      this.update({ password: this.password })
        .catch((error) => {
          this.errorMessage = error.statusText;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
